/*

    Quiz answer fill component.

*/

import { useFormContext, Controller } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { QuizForm } from './Quiz'
import { QuizType } from '../types/quiz'

export default function QuizAnswerFill(props: {
  quiz: QuizType,
}) {
  const { control } = useFormContext<QuizForm>()

  return (
    <Stack gap={2}>
      {props.quiz.answers.map((answer, index) => (
        <Controller
          key={index}
          name={`answers.${index}.answer`}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label={`(${index + 1})の回答`}
              fullWidth
            />
          )}
        />
      ))}
    </Stack>
  )
}
