/*

    Quiz text component.

*/

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { visit } from 'unist-util-visit'

export default function QuizText(props: {
  content: string,
}) {
  if (!props.content) {
    return null
  }

  let currentNumber = 0
  const regex = /##(.*?)##/g
  const maskedContent = props.content.replace(regex, () => {
    currentNumber++
    return `##(${currentNumber})##`
  })

  return (
    <Stack direction="column" gap={2}>
      <ReactMarkdown
        children={maskedContent}
        remarkPlugins={[remarkMasked]}
        components={{
          a: ({ children, href }) => (
            <Link href={href as string}>{children}</Link>
          ),
          blockquote: ({ children }) => (
            <Typography paragraph mb={0}>
              {children}
            </Typography>
          ),
          code: (props) => {
            const {children, className} = props
            const match = /language-(\w+)/.exec(className || '')
            return match ? (
              <SyntaxHighlighter
                PreTag="div"
                children={String(children).replace(/\n$/, '')}
                language={match[1]}
                style={atomOneDark}
              />
            ) : (
              <Box
                component="span"
                className={className}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  borderRadius: 1,
                  padding: 1,
                  margin: 1,
                }}
              >
                {children}
              </Box>
            )
          },
          em: ({ children }) => {
            return (
              <Typography
                component="span"
                sx={{
                  display: 'inline-block',
                  border: '1px solid rgba(0, 0, 0, 1)',
                  borderRadius: 1,
                  padding: 1,
                  margin: 1,
                  width: '100px',
                  textAlign: 'center',
                  marginBottom: 0,
                }}
              >
                {children}
              </Typography>
            )
          },
          hr: () => <Divider />,
          img: ({ alt, src }) => (
            <Box
              component="img"
              alt={alt as string}
              src={src as string}
            />
          ),
          li: ({ children }) => (
            <ListItem sx={{ display: 'list-item' }}>{children}</ListItem>
          ),
          ol: ({ children }) => (
            <List
              component="ol"
              sx={{
                listStyle: 'dicimal inside',
                padding: 0,
              }}
            >
              {children}
            </List>
          ),
          p: ({ children }) => (
            <Typography paragraph mb={0}>{children}</Typography>
          ),
          pre: ({ children }) => <>{children}</>,
          strong: ({ children }) => (
            <Typography component="span" fontWeight="bold">
              {children}
            </Typography>
          ),
          ul: ({ children }) => (
            <List sx={{ listStyle: 'disc inside', padding: 0 }}>
              {children}
            </List>
          ),
        }}
      />
    </Stack>
  )
}

const remarkMasked = () => {
  const transformer = (tree: any) => {
    visit(tree, (node, index, parent) => {
      const regex = /##(.*?)##/g
      let match
      const texts = []
      let lastIndex = 0

      while ((match = regex.exec(node.value)) !== null) {
        const start = match.index
        const end = regex.lastIndex

        if (start > lastIndex) {
          texts.push({
            type: 'text',
            value: node.value.slice(lastIndex, start),
          })
        }

        texts.push({
          type: 'emphasis',
          children: [{type: 'text', value: match[1]}],
        })

        lastIndex = end
      }

      const length = node.value?.length || 0
      if (lastIndex < length) {
        texts.push({
          type: 'text',
          value: node.value.slice(lastIndex),
        })
      }

      if (texts.length > 1 && parent.type === 'paragraph') {
        parent.children.splice(index, 1, ...texts)
      }
    })
  }

  return transformer
}
