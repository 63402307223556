/*

    QUiz results.

*/

import React from 'react'
import * as iconv from 'iconv-lite'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import DownloadIcon from '@mui/icons-material/Download'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ReplayIcon from '@mui/icons-material/Replay'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import QuizHeader from './QuizHeader'
import QuizText from './QuizText'
import { QuizResultType } from '../types/quiz'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  width: '100px',
  fontWeight: 'bold',
}))

export default function QuizResults(props: {
  resultList: QuizResultType[],
  onRetry: () => void,
}) {
  const total = props.resultList.length
  const correct = props.resultList.filter((result) => result.isCorrect).length

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [menuOpen, setMenuOpen] = React.useState(false)

  const handleDownload = ( encoding: string ) => {
    const csv = [
      [total + '問中' + correct + '問正解'],
      ['問題', '結果', '回答', '正解'],
      ...props.resultList.map((result) => [
        result.quiz.title,
        result.isCorrect ? '正解' : '不正解',
        result.answers.join(','),
        result.quiz.answers.join(','),
      ]),
    ]
      .map((row) => row.map((cell) => `"${cell}"`).join(','))
      .join('\n')

    const encoded = iconv.encode(csv, encoding)

    const date = new Date()
    const dateStr = date.getFullYear()
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + ('0' + date.getDate()).slice(-2)
    const blob = new Blob([encoded], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `knowledge-quiz-result-${dateStr}.csv`
    a.click()
  }

  return (
    <Stack gap={2}>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h2"
            sx={{ mb: 2 }}
          >
            Knowledge Quiz
          </Typography>
          <Stack gap={2}>
            <Typography>
              {total}問中{correct}問正解しました！
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      {props.resultList.map((result) => (
        <Card>
          <CardContent>
            <Stack gap={2}>
              <QuizHeader title={result.quiz.title} />
              <QuizText content={result.quiz.body} />
              <Divider sx={{ my: 2 }} />
              <TableContainer>
                <Table>
                  <TableBody>
                    <StyledTableRow>
                       <StyledTableHeadCell
                        component="th"
                        scope="row"
                      >
                        結果
                      </StyledTableHeadCell>
                      <TableCell>
                        {result.isCorrect ? (
                          <Typography
                            color="success.main"
                            fontWeight="bold"
                          >
                            正解
                          </Typography>
                        ) : (
                          <Typography
                            color="error.main"
                            fontWeight="bold"
                          >
                            不正解
                          </Typography>
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableHeadCell
                        component="th"
                        scope="row"
                      >
                        回答
                      </StyledTableHeadCell>
                      <TableCell>
                        {result.answers.map((answer) => (
                          <Typography>{answer}</Typography>
                        ))}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableHeadCell
                        component="th"
                        scope="row"
                      >
                        正解
                      </StyledTableHeadCell>
                      <TableCell>
                        {result.quiz.answers.map((answer) => (
                          <Typography>{answer}</Typography>
                        ))}
                      </TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </CardContent>
        </Card>
      ))}
      <Stack direction="row" gap={2} justifyContent="end">
        <Button
          variant="outlined"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
            setMenuOpen(true)
          }}
          startIcon={<DownloadIcon />}
        >
          結果をダウンロードする
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setMenuOpen(false)}
        >
          <MenuItem onClick={() => handleDownload('utf8')}>CSV (UTF-8)</MenuItem>
          <MenuItem onClick={() => handleDownload('shift_jis')}>CSV (Shift JIS)</MenuItem>
        </Menu>
        <Button
          variant="contained"
          onClick={props.onRetry}
          startIcon={<ReplayIcon />}
        >
          もう一度挑戦する
        </Button>
      </Stack>
    </Stack>
  )
}
