/*

    Quiz answer multiple choice component.

*/

import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import { QuizForm } from './Quiz'
import { QuizType } from '../types/quiz'
import { arrayShuffle } from '../utils/array'

export default function QuizAnswerMultipleChoice(props: {
  quiz: QuizType,
}) {
  const { control } = useFormContext<QuizForm>()
  const [choices, setChoices] = React.useState<string[]>([])

  React.useEffect(() => {
    setChoices(arrayShuffle(props.quiz.choices))
  }, [props.quiz.choices])

  return (
    <Stack gap={1}>
      {choices.map((choice, index) => (
        <Controller
          name={`answers.${index}.answer`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              label={choice}
              value={choice}
              control={
                <Checkbox
                  {...field}
                  checked={field.value === choice}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? choice : '')
                  }}
                />
              }
              key={index}
            />
          )}
        />
      ))}
    </Stack>
  )
}
