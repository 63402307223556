/*

    Quiz header component.

*/

import Typography from '@mui/material/Typography'

export default function QuizHeader(props: {
  title: string,
}) {
  return (
    <Typography
      variant="h5"
      component="h2"
    >
      {props.title}
    </Typography>
  )
}
