/*

    Quiz answer single choice component.

*/

import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { QuizForm } from './Quiz'
import { QuizType } from '../types/quiz'
import { arrayShuffle } from '../utils/array'

export default function QuizAnswerSingleChoice(props: {
  quiz: QuizType,
}) {
  const { control } = useFormContext<QuizForm>()
  const [choices, setChoices] = React.useState<string[]>([])

  React.useEffect(() => {
    setChoices(arrayShuffle(props.quiz.choices))
  }, [props.quiz.choices])

  return (
    <Controller
      name={`answers.0.answer`}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <RadioGroup
          {...field}
          value={field.value}
          onChange={(_, value) => field.onChange(value)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
          {choices.map((choice, index) => (
            <FormControlLabel
              key={index}
              label={choice}
              value={choice}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}
