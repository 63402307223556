/*

    Quiz answer sorting component.

*/

import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
} from '@dnd-kit/core'
import { arrayMove, useSortable, SortableContext } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import ListItem from '@mui/material/ListItem'
import SortIcon from '@mui/icons-material/Sort'
import Stack from '@mui/material/Stack'
import { QuizForm } from './Quiz'
import { QuizType } from '../types/quiz'
import { arrayShuffle } from '../utils/array'

function QuizAnswerSortingItem(props: {
  id: string,
  choice: string,
}) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id })

  return (
    <ListItem
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
      {...attributes}
      {...listeners}
    >
      <SortIcon sx={{ mr: 2 }} />
      {props.choice}
    </ListItem>
  )
}

export default function QuizAnswerSorting(props: {
  quiz: QuizType,
}) {
  const [choices, setChoices] = React.useState<string[]>([])
  const { setValue } = useFormContext<QuizForm>()

  React.useEffect(() => {
    const shuffledChoices = arrayShuffle(props.quiz.choices)
    setChoices(shuffledChoices)
  }, [props.quiz.choices])

  React.useEffect(() => {
    setValue('answers', choices.map((choice) => ({ answer: choice })))
  }, [choices, setValue])

  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={[touchSensor, mouseSensor]}
      onDragEnd={(event) => {
        const { active, over } = event
        if (over == null || active.id === over.id) return
        const oldIndex = choices.indexOf(active.id as string)
        const newIndex = choices.indexOf(over.id as string)
        const newChoices = arrayMove(choices, oldIndex, newIndex)
        setChoices(newChoices)
        setValue('answers', newChoices.map((choice) => ({ answer: choice })))
      }}
    >
      <Stack gap={1}>
        <SortableContext items={choices}>
          {choices.map((choice, index) => (
            <QuizAnswerSortingItem key={choice} id={choice} choice={choice} />
          ))}
        </SortableContext>
      </Stack>
    </DndContext>
  )
}
