import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

export default function QuizStart(props: {
  numberOfQuizzes: number,
  setNumberOfQuizzes: (value: number) => void,
  timer: number,
  setTimer: (value: number) => void,
  onStart: () => void,
}) {
  return (
    <Card>
      <CardContent>
        <Typography
          variant="h4"
          component="h2"
          sx={{ mb: 2 }}
        >
          Knowledge Quiz
        </Typography>
        <Stack gap={4}>
          <Typography>
            クイズに挑戦して、自分の知識を試してみましょう！
          </Typography>
          <TextField
            label="問題数"
            value={props.numberOfQuizzes}
            onChange={(e) => {
              props.setNumberOfQuizzes(parseInt(e.target.value || '0'))
            }}
          />
          <TextField
            label="1問当たりの制限時間（秒）"
            helperText="※ 0の場合は無制限"
            value={props.timer}
            onChange={(e) => {
              props.setTimer(parseInt(e.target.value || '0'))
            }}
          />
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onStart}
        >
          開始
        </Button>
      </CardActions>
    </Card>
  )
}
