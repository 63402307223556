/*

    QUiz result dialog.

*/

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { QuizResultType } from '../types/quiz'

export default function QuizResultDialog(props: {
  open: boolean,
  result: QuizResultType | null,
  onNext: () => void,
}) {
  if (!props.result) {
    return <></>
  }
  const quiz = props.result.quiz

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle>
        {props.result.isCorrect ? (
          <Typography
            color="success.main"
            fontWeight="bold"
          >
            正解
          </Typography>
        ) : (
          <Stack>
            <Typography
              color="error.main"
              fontWeight="bold"
            >
              不正解
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        {quiz.answers.map((answer, index) => (
          <Typography key={index}>
            {answer}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onNext}
          autoFocus
        >
          次の問題
        </Button>
      </DialogActions>
    </Dialog>
  )
}
