/*

    Application root component.

*/

import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Quiz from './components/Quiz'
import QuizResults from './components/QuizResults'
import QuizResultDialog from './components/QuizResultDialog'
import QuizStart from './components/QuizStart'
import { API_URL } from './config/env'
import { QuizForm } from './components/Quiz'
import { QuizType, QuizResultType } from './types/quiz'

export default function App() {
  const [quizList, setQuizList] = React.useState<QuizType[]>([])
  const [resultList, setResultList] = React.useState<QuizResultType[]>([])

  const [numberOfQuizzes, setNumberOfQuizzes] = React.useState<number>(
    parseInt(localStorage.getItem('numberOfQuizzes') || '10')
  )
  const [currentNumber, setCurrentNumber] = React.useState<number>(0)
  const [currentQuiz, setCurrentQuiz] = React.useState<QuizType | null>(null)
  const [currentResult, setCurrentResult] = React.useState<QuizResultType | null>(null)

  const [timer, setTimer] = React.useState<number>(
    parseInt(localStorage.getItem('timer') || '60')
  )

  const [fetching, setFetching] = React.useState<boolean>(false)
  const [finished, setFinished] = React.useState<boolean>(false)
  const [openResultDialog, setOpenResultDialog] = React.useState<boolean>(false)

  const [darkMode, setDarkMode] = React.useState<boolean>(
    localStorage.getItem('darkMode') === 'true'
  )
  const handleDarkModeOn = () => {
    setDarkMode(true)
    localStorage.setItem('darkMode', 'true')
  }
  const handleDarkModeOff = () => {
    setDarkMode(false)
    localStorage.setItem('darkMode', 'false')
  }
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 6,
        }
      }
    }
  })

  const fetchQuiz = () => {
    setFetching(true)
    fetch(`${API_URL}/api/get_random_quiz`)
      .then(async (res) => {
        const quiz: QuizType = await res.json()
        const newQuizList = [...quizList, quiz]
        setQuizList(newQuizList)
        setCurrentQuiz(quiz)
        setFetching(false)
      })
  }

  const handleStart = () => {
    localStorage.setItem('numberOfQuizzes', numberOfQuizzes.toString())
    localStorage.setItem('timer', timer.toString())
    fetchQuiz()
  }

  const handleAnswer = (form: QuizForm) => {
    if (!currentQuiz) return
    const answers = form.answers.map((answer) => answer.answer)
    const correctAnswers = currentQuiz.answers
    const result = {
      isCorrect: false,
      quiz: currentQuiz,
      answers,
    }
    if (
      currentQuiz.type === 'fill-in-the-blank' ||
      currentQuiz.type === 'sorting'
    ) {
      result.isCorrect = correctAnswers.join('') === answers.join('')
    } else if (currentQuiz.type === 'text') {
      result.isCorrect = correctAnswers.includes(answers[0])
    } else {
      result.isCorrect= correctAnswers.sort().join('') === answers.sort().join('')
    }
    setCurrentResult(result)
    setResultList(prev => [...prev, result])
    setOpenResultDialog(true)
  }

  const handleNext = () => {
    setOpenResultDialog(false)
    const nextNumber = currentNumber + 1
    if (nextNumber >= numberOfQuizzes) {
      setFinished(true)
      setCurrentQuiz(null)
      return
    }
    setCurrentNumber(nextNumber)
    fetchQuiz()
  }

  const handleRetry = () => {
    setFinished(false)
    setCurrentNumber(0)
    setQuizList([])
    setResultList([])
    setCurrentQuiz(null)
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar>
        <Toolbar>
          <Typography
            component="div"
            variant="h6"
            sx={{ flexGrow: 1, textAlign: 'center' }}
          >
            Knowledge Quiz
          </Typography>
          {darkMode ? (
            <IconButton onClick={handleDarkModeOff}>
              <Brightness7Icon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDarkModeOn}>
              <Brightness4Icon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Paper sx={{ minHeight: '100vh' }} elevation={2}>
        <Container sx={{ py: 4 }}>
          <Toolbar />
          {fetching ? (
            <Card sx={{ p: 4 }}>
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            </Card>
          ) : currentQuiz ? (
            <Quiz
              totalNumber={numberOfQuizzes}
              currentNumber={currentNumber + 1}
              quiz={currentQuiz}
              onAnswer={handleAnswer}
              timer={timer}
            />
          ) : finished ? (
            <QuizResults resultList={resultList} onRetry={handleRetry} />
          ) : (
            <QuizStart
              numberOfQuizzes={numberOfQuizzes}
              setNumberOfQuizzes={setNumberOfQuizzes}
              timer={timer}
              setTimer={setTimer}
              onStart={handleStart}
            />
          )}
          {openResultDialog && (
            <QuizResultDialog
              open={openResultDialog}
              result={currentResult}
              onNext={handleNext}
            />
          )}
        </Container>
      </Paper>
    </ThemeProvider>
  )
}
