/*

    Quiz component.

*/

import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import QuizAnswerFill from './QuizAnswerFill'
import QuizAnswerMultipleChoice from './QuizAnswerMultipleChoice'
import QuizAnswerSingleChoice from './QuizAnswerSingleChoice'
import QuizAnswerSorting from './QuizAnswerSorting'
import QuizAnswerText from './QuizAnswerText'
import QuizHeader from './QuizHeader'
import QuizText from './QuizText'
import { QuizType } from '../types/quiz'

export type QuizForm = {
  answers: { answer: string }[]
}

export default function Quiz(props: {
  totalNumber: number,
  currentNumber: number,
  quiz: QuizType,
  onAnswer: (form: QuizForm) => void,
  timer: number,
}) {
  const [timeLeft, setTimeLeft] = React.useState(props.timer)
  const [submitted, setSubmitted] = React.useState(false)

  const methods = useForm<QuizForm>({
    defaultValues: {
      answers: [],
    },
  })

  const getAnswerArea = () => {
    switch (props.quiz.type) {
      case 'fill-in-the-blank':
        return <QuizAnswerFill quiz={props.quiz} />
      case 'multiple-choice':
        return <QuizAnswerMultipleChoice quiz={props.quiz} />
      case 'single-choice':
        return <QuizAnswerSingleChoice quiz={props.quiz} />
      case 'sorting':
        return <QuizAnswerSorting quiz={props.quiz} />
      case 'text':
        return <QuizAnswerText quiz={props.quiz} />
    }
  }

  const onSubmit = (data: QuizForm) => {
    setSubmitted(true)
    props.onAnswer(data)
  }

  React.useEffect(() => {
    methods.reset({ answers: [{ answer: '' }] })
  }, [props.quiz, methods])

  // Timer
  React.useEffect(() => {
    if (props.timer > 0 && timeLeft <= 0 && !submitted) {
      methods.handleSubmit(onSubmit)()
      return
    }

    if (submitted || timeLeft <= 0) {
      return
    }

    const intervalId = setInterval(() => {
      if (submitted) {
        clearInterval(intervalId)
        return
      }
      setTimeLeft((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  })
  const timeProgress = props.timer > 0 ? (timeLeft / props.timer) * 100 : 0

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Card elevation={6}>
          <CardContent>
            <Stack gap={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ width: '50%' }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={(props.currentNumber / props.totalNumber) * 100}
                    sx={{ flexGrow: 1, mr: 2 }}
                  />
                  <Typography>
                    {props.currentNumber}/{props.totalNumber}
                  </Typography>
                </Stack>
                {props.timer > 0 && (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-flex',
                      ml: 2,
                      borderRadius: '100%',
                      backgroundColor: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={timeProgress}
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                      >
                        {timeLeft}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Stack>
              <QuizHeader title={props.quiz.title} />
              <QuizText content={props.quiz.body} />
            </Stack>
            <Divider sx={{ my: 4 }} />
            {getAnswerArea()}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              回答
            </Button>
          </CardActions>
        </Card>
      </Box>
    </FormProvider>
  )
}
