/*

    Quiz answer text component.

*/

import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { QuizForm } from './Quiz'
import { QuizType } from '../types/quiz'

export default function QuizAnswerText(props: {
  quiz: QuizType,
}) {
  const { control } = useFormContext<QuizForm>()

  return (
    <Controller
      name={`answers.0.answer`}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          placeholder="回答を入力"
          autoComplete="off"
          autoFocus
          fullWidth
        />
      )}
    />
  )
}
